<template>
	<div class="enterprise">


		<div class="tab_info">
			<div style="flex: 1;">
				<a-tabs type="card" v-model:activeKey="activeKeyTab" @change='changeTab'>
					<a-tab-pane key="1" tab="个人信息">
					</a-tab-pane>
					<a-tab-pane key="2" tab="入职信息" v>
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>

		<div class="FieldSettingsBox">
			<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
				<span>
					&nbsp; &nbsp;
				</span> 
				<span>字段名称</span>
				<span>是否显示</span>
<span>是否必填</span>
			</div>
			<!-- <draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
				@start="onStart" @end="onEnd">
				<transition-group> -->
					<div class="FieldSettingsBoxTitle" v-for="element in all_list" :key="element.id">
						<span>
							&nbsp; &nbsp;
						</span>
						<span>
							{{element.name}}
						</span>
						<span class="cq_text_require">
							<a-checkbox :disabled='element.can_edit==-1' v-model:checked="element.check_show"></a-checkbox>
						</span>
						<span class="cq_text_require">
							<a-checkbox :disabled='element.can_edit==-1' v-model:checked="element.check_need"></a-checkbox>
						</span>
					</div>
					<!-- </transition-group>
			</draggable> -->
		</div>
		<div>
			<a-drawer placement="bottom" height="70" :mask="false" :visible="batchDeleteVisible" :closable="false"
			  >
			    <a-button type="primary" style="margin-left: 50%;" @click='handle_submit' class='pointerCancel'>保存</a-button>
			</a-drawer>
		</div>
	</div>
</template>

<script>
	import {
		getPersonnelUserRule,changePersonnelUserRule
	} from '@/api/enterprise.js'
	

	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	export default {
		components: {

		},
		data() {
			return {
				batchDeleteVisible:true,
				activeKeyTab: '1',
				all_list: [],
			}

		},
		created() {
			this.type = this.$route.query.type
			this.get_info()
		},
		methods: {
			cancel(){},
			changeTab(){
				this.get_info()
			},
			onStart() {
				this.drag = true;
			},
			onEnd() {
				this.drag = false;
			},
			get_info() {
				getPersonnelUserRule({data:{
					type:this.type,
					flag:this.activeKeyTab
				}}).then(res => {
					res.data.list.forEach(item=>{
						item.check_need = item.is_need==1?true:false
						item.check_show= item.is_show==1?true:false
					})
					this.all_list = res.data.list
				})
			},
			handle_submit() {
				this.all_list.forEach(item=>{
					item.is_need = item.check_need ?1:-1
					item.is_show = item.check_show ?1:-1
				})
				changePersonnelUserRule({data:{
					value:this.all_list
				}}).then(res=>{
						message.success('操作成功');
					this.get_info()
				})
			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.count_info {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}

	/*被拖拽对象的样式*/
	.item {
		padding: 6px;
		// background-color: #fdfdfd;
		// border: solid 1px #eee;
		// margin-bottom: 10px;
		cursor: move;
		width: 100%;
		display: flex;
		align-items: center;

		span:nth-child(1) {
			flex: 1;
		}
	}

	/*选中样式*/
	.chosen {
		border: solid 2px #3089dc !important;
	}

	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;
		margin-bottom: 30px;

	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 1;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 2;
			text-align: center;

		}


	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}
</style>
